<template>
  <div class="office-wrapper">
    <div class="office-content">
      <div class="section-title">
        личный кабинет
      </div>

      <!-- <h3 v-if="!user"
          class="mt-5"
          style="color: #243E89;">Авторизуйтесь, чтобы получить доступ к личному кабинету!</h3> -->

      <div class="wrapper"
           v-if="user">
        <b-col xl="4"
               lg="12"
               md="12"
               class="no-gutters mr-5 d-flex flex-column align-items-center">
          <div class="info">
            <div class="info-title">Персональные данные</div>

            <div class="info-line">
              <p>Имя:</p>

              <p>{{ user.name }}</p>
            </div>

            <div class="info-line">
              <p>Фамилия:</p>

              <p>{{ user.surname }}</p>
            </div>

            <div class="info-line">
              <p>Мобильный телефон:</p>

              <p>{{ user.phone }}</p>
            </div>

            <div class="info-line">
              <p>E-mail:</p>

              <p>{{ user.email }}</p>
            </div>
          </div>

          <b-button class="button button-orange"
                    onclick="ym(94436170,'reachGoal','lk_check')"
                    v-b-modal.upld-modal>Загрузить чек</b-button>

          <b-button class="button button-orange mt-3 mb-3"
                    @click="logout">Выйти</b-button>
        </b-col>

        <b-col xl=""
               lg=""
               md=""
               class="no-gutters table">
          <p class="vouchers">Загруженные чеки</p>

          <OfficeTable />

          <!-- <p class="vouchers mt-5">Сертификаты</p>

          <div class="certs-row" style="color: white;"
               v-if="certs"
               v-for="cert in certs">
            <p class="mr-4 mb-0" style="text-transform: uppercase;">{{ cert.number }}</p>
            <a :href="cert.link" target="blank" style="color: white;">({{ cert.link }})</a>
          </div> -->
        </b-col>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { validationMixin } from "vuelidate"
import OfficeTable from '@/components/OfficeTable.vue'
import { mapState, mapGetters, mapMutations } from "vuex"
import { required, requiredIf, minLength, maxLength, or, helpers } from "vuelidate/lib/validators"

export default {
  name: 'Office',

  components: {
    OfficeTable,
  },

  mixins: [validationMixin],

  validations: {
    item: {
      oldPassword: {
        required
      },
      password: {
        required,
      },
    }
  },

  data: () => ({
  }),

  computed: {
    ...mapGetters({
      user: 'user',
      certs: 'certs',
      token: 'token'
    }),
  },

    mounted(){
    if (!this.token){
      this.$router.push('/')
    }
  },

  methods: {
    ...mapMutations({
      saveUser: 'saveUser',
      saveToken: 'saveToken'
    }),

    openModal(){
      this.$bvModal.show('plug-modal')
    },

    logout(){
      this.saveToken(null)
      this.saveUser(null)
      localStorage.removeItem('token')
      axios.defaults.headers.authorization = ''
      window.location.reload()
    },

    validateState(item) {
      const { $dirty, $error } = item
      return $dirty ? !$error : null
    },
  },
}
</script>

<style lang="scss" scoped>
.office-wrapper {
  font-family: $regular !important;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #EEF3F5;
  padding-top: 90px;
  min-height: calc(100vh - 336px);
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('@/assets/office-bg.png');

  @media(max-width: 1200px){
    flex-direction: column;
  }

  .borders {
    border-radius: 25px !important;
    margin-top: 30px;
    padding-top: 60px !important;
  }

  .office-content {
    max-width: 1600px;
    background-color: transparent;
    width: 100%;
    padding: 30px 35px;
    padding-top: 90px;
    min-height: 600px;

    @media(max-width: 1200px){
      min-height: auto;
      border-radius: 0 0 25px 25px;
    }

    @media(max-width: 768px){
      padding-top: 30px;
      padding-left: 20px;
      padding-right: 20px;
    }
  }
}

.wrapper {
  display: flex;
  align-items: start;
  justify-content: space-between;

  @media(max-width: 1300px){
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
}

.section-title {
  white-space: nowrap;
  font-family: $regular;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  text-transform: uppercase;
  margin-bottom: 60px;
  text-align: center;
  color: $blue;
  padding-right: 10%;

  @media(max-width: 999px){
    padding-right: 0;
  }

  @media(max-width: 768px){
    white-space: normal;
    margin-bottom: 30px;
  }
}

.info {
  background-color: transparent;
  border-radius: 30px;
  border: 2px solid #FFF;
  background: $white;
  font-family: $regular;
  padding: 30px;

  @media(max-width: 768px){
    padding: 20px 10px;
    width: 100%;
  }

  .info-title {
    text-align: center;
    margin-bottom: 32px;
    color: $blue;
    font-family: $regular;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 110%;
    text-transform: uppercase;

    @media(max-width: 768px){
      font-size: 18px;
    }
  }

  .info-line {
    display: flex;
    align-items: center;
    margin-top: 15px;
    width: 100%;
    color: $darker-grey;
    max-width: 410px;
    white-space: normal;
    border-bottom: 1px dotted #CACACA;
    padding-bottom: 10px;

    p {
      &:first-child {
        width: 210px;
        font-weight: 600;

        @media(max-width: 576px){
          width: 160px;
        }
      }
    }
  }

  p {
    text-align: left;
    font-size: 15px;
    margin: 0;
    font-family: $regular;

    &:first-child {
      margin-bottom: 5px;
    }

    @media(max-width: 768px){
      font-size: 14px;
      white-space: nowrap;
    }

    @media(max-width: 450px){
      font-size: 11px;
      white-space: nowrap;
    }
  }
}

.user-info {
  text-align: left;
  padding-left: 20px;

  p {
    color: $black;
    font-size: 16px;
    margin-bottom: 3px;
  }
}

.no-gutters {
  padding: 0px;
}

.button {
  width: 100%;
  margin-top: 30px;

  &:hover {
    opacity: 0.9;
  }
}

.button {
  margin-top: 40px;
  width: 100%;

  &:hover {
    opacity: 0.8 !important;
  }
}

h5 {
  text-align: center;
  margin-top: -30px;
  font-size: 16px;
  opacity: 0.4;
  font-weight: 400;
}

.modal-form {
  padding: 0px !important;
}

a {
  color: $red;
}

#password-change {
  margin-top: 40px;
}

.input-icon {
  left: 0;
}

.button-link {
  background-color: transparent !important;
  border: none !important;
  color: $black !important;
  margin-top: 5px;
  font-size: 14px;
}

.desktop {
  @media(max-width: 1200px){
    display: none;
  }
}

.mobile {
  display: none;

  @media(max-width: 1200px){
    display: block;
  }
}

.mr-5 {
  @media(max-width: 1200px){
    margin-right: 0px !important;
  }
}

.btn-upload {
  margin-top: 0;
  margin-bottom: 55px;
}

.vouchers {
  color: #243E89;
  font-family: $regular;
  margin-bottom: 32px;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  text-transform: uppercase;
}

.table {
  margin-top: 0px;
}

.certs-row {
  display: flex;
  margin-bottom: 12px;
  align-items: center;

  @media(max-width: 768px){
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
}
</style>
