<template>
  <section class="section-wrapper" id="where-to-buy">
    <div class="content-wrapper">
      <h3 class="section-title">
        инновационные <br>
        водоградиентные <br class="mobile"> линзы ALCON <br>
        <span style="color: #29AAE1;">ДЛЯ НЕЗАБЫВАЕМЫХ ВПЕЧАТЛЕНИЙ!</span>
      </h3>

      <p class="subtext">
        Забудьте, что вы в линзах! Проведите время активно и ярко вместе с Alcon! <br class="desktop">
        Покоряйте новые вершины, подарите себе радостные эмоции, занимайтесь <br>
        спортом с уверенностью в комфорте!
      </p>

      <div class="lenses-wrapper desktop mt-5">
        <div class="lense">
          <p class="lense-text">
            ~100% влагосодержание <br>на поверхности<sup style="font-size: 11px;">7</sup>
          </p>

          <img class="lense-img" src="@/assets/lenses-1.webp" />

          <div class="lense-info">
            <p class="lense-title">
              TOTAL30
            </p>

            <p class="lense-subtitle">
              линзы на месяц ношения
            </p>

            <p class="lense-description">
              Линзы, которые не чувствуешь даже
              на 30 день!<sup style="font-size: 11px;">4</sup>
            </p>

            <div class="lense-link button-orange">
              <a href="https://www.moiglaza.ru/products/kontaktnye-linzy/total30/" target="_blank">
                <span style="text-decoration:underline">Подробнее </span>
              </a>
            </div>
          </div>
        </div>

        <div class="lense">
          <p class="lense-text">
            ~100% влагосодержание <br>на поверхности<sup style="font-size: 11px;">6</sup>
          </p>

          <img class="lense-img" src="@/assets/lenses-2.webp" />

          <div class="lense-info">
            <p class="lense-title">
              DAILIES TOTAL1
            </p>

            <p class="lense-subtitle">
              однодневные контактные линзы
            </p>

            <p class="lense-description">
              Линзы, которые не чувствуешь<sup style="font-size: 11px;">2</sup> с утра
              и до позднего вечера!<sup style="font-size: 11px;">3</sup>
            </p>

            <div class="lense-link button-orange">
              <a href="https://www.moiglaza.ru/products/kontaktnye-linzy/dailies-total-1/" target="_blank">
                <span style="text-decoration:underline">Подробнее </span>
              </a>
            </div>
          </div>
        </div>

        <div class="lense">
          <p class="lense-text">
            87% влагосодержание <br>на поверхности<sup style="font-size: 11px;">5</sup>
          </p>

          <img class="lense-img" src="@/assets/lenses-3.webp" />

          <div class="lense-info">
            <p class="lense-title">
              Precision1
            </p>

            <p class="lense-subtitle">
              однодневные контактные линзы
            </p>

            <p class="lense-description">
              16 часов комфорта и четкого зрения<sup style="font-size: 11px;">1</sup>
            </p>

            <div class="lense-link button-orange">
              <a href="https://www.moiglaza.ru/products/kontaktnye-linzy/precision1/" target="_blank">
                <span style="text-decoration:underline">Подробнее </span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="lenses-wrapper tablet mt-5">
        <div class="lense">
          <p class="lense-text">
            ~100% влагосодержание <br>на поверхности<sup style="font-size: 11px;">7</sup>
          </p>

          <img class="lense-img" src="@/assets/lenses-1@mobile.webp" />

          <div class="lense-info">
            <p class="lense-title">
              TOTAL30
            </p>

            <p class="lense-subtitle">
              линзы на месяц ношения
            </p>

            <p class="lense-description">
              Линзы, которые не чувствуешь даже
              на 30 день!<sup style="font-size: 11px;">4</sup>
            </p>

            <div class="lense-link button-orange"
                 onclick="ym(94436170,'reachGoal','Total 30')">
              <a href="https://www.moiglaza.ru/products/kontaktnye-linzy/total30/" target="_blank">
                <span style="text-decoration:underline">Подробнее </span>
              </a>
            </div>
          </div>
        </div>

        <div class="lense">
          <p class="lense-text">
            ~100% влагосодержание <br>на поверхности<sup style="font-size: 11px;">6</sup>
          </p>

          <img class="lense-img" src="@/assets/lenses-2@mobile.webp" />

          <div class="lense-info">
            <p class="lense-title">
              DAILIES TOTAL1
            </p>

            <p class="lense-subtitle">
              однодневные контактные линзы
            </p>

            <p class="lense-description">
              Линзы, которые не чувствуешь<sup style="font-size: 11px;">2</sup> с утра
              и до позднего вечера!<sup style="font-size: 11px;">3</sup>
            </p>

            <div class="lense-link button-orange"
                 onclick="ym(94436170,'reachGoal','DAILIES_TOTAL1')">
              <a href="https://www.moiglaza.ru/products/kontaktnye-linzy/dailies-total-1/" target="_blank">
                <span style="text-decoration:underline">Подробнее </span>
              </a>
            </div>
          </div>
        </div>

        <div class="lense">
          <p class="lense-text">
            87% влагосодержание <br>на поверхности<sup style="font-size: 11px;">5</sup>
          </p>

          <img class="lense-img" src="@/assets/lenses-3@mobile.webp" />

          <div class="lense-info">
            <p class="lense-title">
              Precision1
            </p>

            <p class="lense-subtitle">
              однодневные контактные линзы
            </p>

            <p class="lense-description">
              16 часов комфорта и четкого зрения<sup style="font-size: 11px;">1</sup>
            </p>

            <div class="lense-link button-orange"
                 onclick="ym(94436170,'reachGoal','Precision1')">
              <a href="https://www.moiglaza.ru/products/kontaktnye-linzy/precision1/" target="_blank">
                <span style="text-decoration:underline">Подробнее </span>
              </a>
            </div>
          </div>
        </div>
      </div>

      <div class="lenses-wrapper mobile">
        <div class="lense" v-if="total30">
          <p class="lense-text">
            ~100% влагосодержание <br>на поверхности<sup style="font-size: 11px;">7</sup>
          </p>

          <img class="lense-img" src="@/assets/lenses-1@mobile.webp" />

          <div class="lense-info">
            <p class="lense-title">
              TOTAL30
            </p>

            <p class="lense-subtitle">
              линзы на месяц ношения
            </p>

            <p class="lense-description">
              Линзы, которые не чувствуешь даже
              на 30 день!<sup style="font-size: 11px;">4</sup>
            </p>

            <div class="lense-link button-orange"
                 onclick="ym(94436170,'reachGoal','Total 30')">
              <a href="https://www.moiglaza.ru/products/kontaktnye-linzy/total30/" target="_blank">
                <span style="text-decoration:underline">Подробнее </span>
              </a>
            </div>
          </div>

          <button class="button button-orange button-action button-action__left disabled">
            <
          </button>

          <button class="button button-orange button-action button-action__right"
                  @click="openTotal1()">
            >
          </button>
        </div>

        <div class="lense" v-if="total1">
          <p class="lense-text">
            ~100% влагосодержание <br>на поверхности<sup style="font-size: 11px;">6</sup>
          </p>

          <img class="lense-img" src="@/assets/lenses-2@mobile.webp" />

          <div class="lense-info">
            <p class="lense-title">
              DAILIES TOTAL1
            </p>

            <p class="lense-subtitle">
              однодневные контактные линзы
            </p>

            <p class="lense-description">
              Линзы, которые не чувствуешь<sup style="font-size: 11px;">2</sup> с утра
              и до позднего вечера!<sup style="font-size: 11px;">3</sup>
            </p>

            <div class="lense-link button-orange"
                 onclick="ym(94436170,'reachGoal','DAILIES_TOTAL1')">
              <a href="https://www.moiglaza.ru/products/kontaktnye-linzy/dailies-total-1/" target="_blank">
                <span style="text-decoration:underline">Подробнее </span>
              </a>
            </div>
          </div>

          <button class="button button-orange button-action button-action__left"
                  @click="openTotal30()">
            <
          </button>

          <button class="button button-orange button-action button-action__right"
                  @click="openPrecision1()">
            >
          </button>
        </div>

        <div class="lense" v-if="precision1">
          <p class="lense-text">
            87% влагосодержание <br>на поверхности<sup style="font-size: 11px;">5</sup>
          </p>

          <img class="lense-img" src="@/assets/lenses-3@mobile.webp" />

          <div class="lense-info">
            <p class="lense-title">
              Precision1
            </p>

            <p class="lense-subtitle">
              однодневные контактные линзы
            </p>

            <p class="lense-description">
              16 часов комфорта и четкого зрения<sup style="font-size: 11px;">1</sup>
            </p>

            <div class="lense-link button-orange"
                 onclick="ym(94436170,'reachGoal','Precision1')">
              <a href="https://www.moiglaza.ru/products/kontaktnye-linzy/precision1/" target="_blank">
                <span style="text-decoration:underline">Подробнее</span>
              </a>
            </div>
          </div>

          <button class="button button-orange button-action button-action__left"
                  @click="openTotal1()">
            <
          </button>

          <button class="button button-orange button-action button-action__right disabled">
            >
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'BuyLenses',

  data: () => ({
    precision1: false,
    total1: false,
    total30: true
  }),

  computed: {
  },

  methods: {
    openTotal1(){
      this.precision1 = false
      this.total1 = true
      this.total30 = false
    },

    openTotal30(){
      this.precision1 = false
      this.total1 = false
      this.total30 = true
    },

    openPrecision1(){
      this.precision1 = true
      this.total1 = false
      this.total30 = false
    },
  }
}
</script>

<style scoped lang="scss">
.section-wrapper {
  background-color: white;
  padding-top: 60px;
  padding-bottom: 40px;
  color: $darker-blue;
  display: flex;
  justify-content: center;
  background-image: url('@/assets/buy-lenses-bg.png');
  background-size: 90%;
  background-repeat: no-repeat;
  background-position: top 100px center;

  @media(max-width: 999px){
    padding-left: 35px;
    padding-right: 35px;
  }

  @media(max-width: 768px){
    padding-bottom: 70px;
    padding-top: 15px;
    background-size: 420%;
    background-position: bottom -15px right;
  }

  @media(max-width: 576px){
    padding: 0 20px;
    padding-top: 44px;
    padding-bottom: 60px;
    background-size: 97% 96%;
    background-position: top 10px center;
    background-image: url('@/assets/buy-lenses-bg@mobile.png');
  }
}

.desktop {
  display: flex !important;

  @media(max-width: 999px){
    display: none !important;
  }
}

.tablet {
  display: none !important;

  @media(max-width: 999px){
    display: flex !important;
  }

  @media(max-width: 768px) {
    display: none !important;
  }

  .lense {
    max-width: 225px;
  }

  .lense-text {
    font-size: 14px !important;
  }

  .lense-title {
    font-size: 24px !important;
    white-space: nowrap;
  }

  .lense-subtitle {
    font-size: 12px !important;
  }

  .lense-description {
    font-size: 14px !important;
  }
}

.mobile {
  display: none !important;

  @media(max-width: 768px){
    display: contents !important;
  }

  img {
    height: 100%;
    max-width: 290px;
    height: 266px;
  }

  .lense {
    align-items: center;
    max-width: 300px;
  }
}

.lenses-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  max-width: 1500px;
  margin-bottom: 100px;

  @media(max-width: 1600px){
    max-width: 1150px;
  }

  @media(max-width: 1400px){
    max-width: 1000px;
  }

  @media(max-width: 1200px){
    padding: 0 25px;
    max-width: 900px;
  }

  @media(max-width: 1100px){
    justify-content: center;
  }

  @media(max-width: 768px){
    margin-bottom: 80px;
  }

  @media(max-width: 576px){
    padding: 0;
  }
}

.lense {
  max-width: 384px;
  width: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media(max-width: 1500px){
    max-width: 354px;
  }

  @media(max-width: 1200px){
    max-width: 260px;
  }

  @media(max-width: 768px){
    max-width: 100%;
    position: relative;
  }

  &:nth-child(2){
    margin-left: 20px;
    margin-right: 20px;
  }

  .lense-text {
    font-weight: 700;
    font-size: 18px;
    line-height: 100%;
    text-align: center;
    color: $darker-grey
  }

  .lense-img {

  }

  .lense-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px;
    background: #EDF4FF;
    box-shadow: 0px 0px 26.8px rgba(0, 48, 135, 0.1);
    border-radius: 20px;
    margin-top: -10px;
    height: 195px;
    position: relative;

    @media(max-width: 1200px){
      height: 250px;
    }

    @media(max-width: 756px){
      height: 170px;
    }
    
    @media(max-width: 576px){
      height: 190px;
    }

    .lense-title {
      font-weight: 700;
      font-size: 32px;
      line-height: 28px;
      text-transform: uppercase;
      color: $blue;
      margin-bottom: 8px;

      @media(max-width: 1200px){
        font-size: 28px;
      }

      @media(max-width: 576px){
        font-size: 26px;
      }
    }

    .lense-subtitle {
      margin: 0;
      font-weight: 600;
      font-size: 14px;
      line-height: 110%;
      text-transform: uppercase;
      color: #003595;
      margin-bottom: 24px;

      @media(max-width: 576px){
        font-size: 12px;
      }
    }

    .lense-description {
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      color: $darker-grey;
      max-width: 90%;

      @media(max-width: 576px){
        font-size: 14px;
      }
    }

    .lense-link {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 52px;
      max-width: 197px;
      width: 100%;
      position: absolute;
      left: 0;
      height: 59px;
      bottom: -24px;
      transform: translateX(-50%);
      right: 50%;
      left: 50%;

      @media(max-width: 1100px) {
        height: 46px;
        font-size: 14px;
      }

      @media(max-width: 576px){
        height: 53px;
      }

      a {
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        text-align: center;
        text-transform: uppercase;
        color: $white;
        text-decoration: none !important;
        transition: .4s all;
        text-decoration: none !important;

        @media(max-width: 1100px){
          font-size: 14px;
        }

        &:hover {
          opacity: .7;
        }

        span {
          text-decoration: none !important;
        }
      }
    }
  }
}


.mobile-title {
  @media(max-width: 768px){
    text-align: center !important;
  }
}

.section-title {
  text-align: center;
  color: $darker-blue;
  font-weight: 700;
  font-family: $regular;

  @media(max-width: 1100px){
    font-size: 30px;

    br {
      display: none;
    }
  }

  @media(max-width: 576px){
    font-size: 24px;

    br {
      display: block;
    }

    .mobile {
      display: none;

      @media(max-width: 768px){
        display: block;
      }
    }
  }
}

.subtext {
  color: $darker-grey;
  margin-bottom: 35px;
  font-size: 20px;
  font-weight: 400;
  font-family: $regular;
  text-align: center;
  line-height: 140%;

  @media(max-width: 768px){
    font-size: 14px;
    margin-bottom: 30px;
  }

  @media(max-width: 576px){
    font-size: 13px;

    br {
      display: none;
    }
  }

  .mobile {
    display: none !important;

    @media(max-width: 768px){
      display: block !important;
    }
  }
}

.offer-wrapper {
  display: flex;
  justify-content: flex-start;
  margin-top: 33px;

  @media(max-width: 768px){
    align-items: flex-start;
    justify-content: center;
    margin-top: 24px;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    &:first-child {
      margin-right: 33px;

      @media(max-width: 999px){
        margin-right: auto;
      }

      @media(max-width: 768px){
        margin-right: 8px;
        justify-content: center;
        align-items: center;
        max-width: 140px;
      }
    }
  }

  .offer-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 27px;
    margin-bottom: 8px;
    text-align: left;

    @media(max-width: 999px){
      br {
        display: none;
      }
    }

    @media(max-width: 768px){
      br {
        display: block;
      }
    }

    @media(max-width: 768px){
      font-size: 20px;
      line-height: 26px;
    }
  }

  .offer-subtext {
    font-size: 16px;
    margin-bottom: 16px;
    line-height: 24px;
    text-align: left;
  }

}

.button {
  color: $darker-blue;
  width: 100%;
  font-size: 16px;
  text-transform: uppercase;
  white-space: nowrap;

  @media(max-width: 768px){
  }

  @media(max-width: 450px){
    max-width: 136px;
    font-size: 14px;
  }

  &:hover {
    text-decoration: none;
  }
}

.content-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  max-width: none;
}

.button-action {
  padding: 0px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50% !important;
  width: 32px !important;
  height: 32px !important;
  position: absolute;
  margin-top: 7%;

  &__right {
    right: 0;

    @media(max-width: 1100px){
      right: 20%;
    }

    @media(max-width: 576px){
      right: 0;
    }
  }

  &__left {
    left: 0;

    @media(max-width: 1100px){
      left: 20%;
    }

    @media(max-width: 576px){
      left: 0;
    }
  }
}

.disabled {
  background: $light-grey;
  pointer-events: none;
}
</style>
