<template>
  <div class="winners-wrapper section-wrapper">
    <!-- <h3 class="section-title">ПОЗДРАВЛЯЕМ НАШИХ ПОБЕДИТЕЛЕЙ!</h3> -->

    <!-- <Slider /> -->

    <h3 class="section-title">РЕЗУЛЬТАТЫ РОЗЫГРЫША</h3>
    <!-- <h5 class="subtext">Смотрите трансляцию подведения итогов розыгрыша</h5> -->

    <div class="winners-content">
      <div class="table-wrapper">
        <div class="actions-wrapper">
          <div class="w-100">
            <div class="mb-3 search-bar">
              <b-form-input id="search-bar"
                            type="search"
                            class="search"
                            @click="putPlaceholder"
                            v-model="searchPhone"
                            invalid-feedback="Некорректный телефон"
                            v-mask="'+7(###)-###-##-##'"
                            maxlength="17"
                            placeholder="Поиск по телефону"
                            v-debounce:400ms="searchWinner"
              >

              </b-form-input>

              <b-button class="button button-search button-orange"
                        variant="primary"
                        @click="searchWinner">
                  <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.4429 15.525L11.6349 11.5645C12.614 10.4005 13.1505 8.93609 13.1505 7.41153C13.1505 3.84955 10.2524 0.951538 6.69046 0.951538C3.12848 0.951538 0.230469 3.84955 0.230469 7.41153C0.230469 10.9735 3.12848 13.8715 6.69046 13.8715C8.02768 13.8715 9.30198 13.4682 10.3915 12.7025L14.2284 16.6931C14.3888 16.8597 14.6045 16.9515 14.8357 16.9515C15.0545 16.9515 15.262 16.8681 15.4196 16.7165C15.7544 16.3943 15.7651 15.8601 15.4429 15.525ZM6.69046 2.63675C9.32333 2.63675 11.4652 4.77866 11.4652 7.41153C11.4652 10.0444 9.32333 12.1863 6.69046 12.1863C4.05759 12.1863 1.91568 10.0444 1.91568 7.41153C1.91568 4.77866 4.05759 2.63675 6.69046 2.63675Z" fill="white"/>
                  </svg>
              </b-button>
            </div>
          </div>

          <!-- <div class="switch-tables">
            <div class="option"
                 :class="{ active : decemberWinners }">
              Декабрь
            </div>
          </div> -->
        </div>

        <div v-if="winnersTable">
          <div class="" v-if="decemberWinners">
            <div class="table-header">
              <div>Имя участника</div>

              <div>Телефон</div>

              <div>Приз</div>
            </div>

            <h5 style="margin-top: 60px; text-align: center; width: 100%;">ПОБЕДИТЕЛИ ЕЩЕ НЕ ОПРЕДЕЛЕНЫ</h5>

            <div class="table-line" v-for="winner in certs">
              <div>{{ winner.name }}</div>

              <div>{{ winner.phone }}</div>

              <div class="bold">{{ winner.prize }}</div>
            </div>

            <div class="table-line" v-for="winner in prizes">
              <div>{{ winner.name }}</div>

              <div>{{ winner.phone }}</div>

              <div>{{ winner.prize }}</div>
            </div>

            <div class="table-line" v-for="winner in sups">
              <div>{{ winner.name }}</div>

              <div>{{ winner.phone }}</div>

              <div>{{ winner.prize }}</div>
            </div>
          </div>

          <div class="" v-if="julyWinners">
            <div class="table-header">
              <div>Имя участника</div>

              <div>Телефон</div>

              <div>Приз</div>
            </div>

            <div class="table-line" v-for="winner in certs">
              <div>{{ winner.name }}</div>

              <div>{{ winner.phone }}</div>

              <div class="bold">{{ winner.prize }}</div>
            </div>

            <div class="table-line" v-for="winner in prizes">
              <div>{{ winner.name }}</div>

              <div>{{ winner.phone }}</div>

              <div>{{ winner.prize }}</div>
            </div>

            <div class="table-line" v-for="winner in sups">
              <div>{{ winner.name }}</div>

              <div>{{ winner.phone }}</div>

              <div>{{ winner.prize }}</div>
            </div>
          </div>

          <div class="" v-if="augustWinners">
            <div class="table-header">
              <div>Имя участника</div>

              <div>Телефон</div>

              <div>Приз</div>
            </div>

            <div class="table-line" v-for="winner in certs">
              <div>{{ winner.name }}</div>

              <div>{{ winner.phone }}</div>

              <div class="bold">{{ winner.prize }}</div>
            </div>

            <div class="table-line" v-for="winner in prizes">
              <div>{{ winner.name }}</div>

              <div>{{ winner.phone }}</div>

              <div>{{ winner.prize }}</div>
            </div>

            <div class="table-line" v-for="winner in sups">
              <div>{{ winner.name }}</div>

              <div>{{ winner.phone }}</div>

              <div>{{ winner.prize }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <a href="/safety.pdf" target="_blank" class="link">
      Техника безопасности по использованию <br class="mobile"> тюбинга (ватрушки)
    </a>
  </div>
</template>

<script>
import axios from 'axios'
import Slider from '@/components/Slider.vue'
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"

export default {
  name: 'WinnersView',

  components: {
    Slider
  },

  data: () => ({
    month: 'june',
    searchPhone: '',
    noResults: false,
    winnersTable: true,
    ratingTable: false,
    sortedWinners: [],
    prizes: [],
    certs: [],
    sups: [],
    decemberWinners: true,
  }),

  computed: {
    ...mapGetters({
      winners: 'winners',
    }),
  },

  mounted() {
    // this.$store.dispatch('getWinners')
    // this.getWinners()
  },

  methods: {
    ...mapMutations({
      setWinners: 'setWinners',
    }),

    getWinners(){
      axios.get('/reference/winners/' + this.month + '?perPage=150', {
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'Access-Control-Allow-Origin': "*"
        }
      }).then((response) => {
          this.certs = []
          this.sups = []
          this.prizes = []
          
          this.sortedWinners = response.data.data.forEach((item) => {
            const name = item.prize.toUpperCase()

            if (name == "СЕРТИФИКАТ НА ПУТЕШЕСТВИЕ") {
              this.certs.push(item)
            } else if (name == "SUP-ДОСКА") {
              this.sups.push(item)
            } else {
              this.prizes.push(item)
            }
          })
      })
    },

    putPlaceholder(){
      if (this.searchPhone.length < 1){
        this.searchPhone = '+7'
      }
    },

    searchWinner(){
      let search = this.searchPhone.toString().replace('-', '')
      search = search.replace('-', '')
      search = search.replace('-', '')

      this.$http.get('/reference/winners/' + this.month + '?perPage=150&search='+ search
          .split('+').join('')
          .split(' ').join('')
          .split('(').join('')
          .split(')').join('')
          .split('+').join('-') + '')
          .then((response) => {
            this.certs = []
            this.sups = []
            this.prizes = []

            this.sortedWinners = response.data.data.forEach((item) => {
              const name = item.prize.toUpperCase()

              if (name == "СЕРТИФИКАТ НА ПУТЕШЕСТВИЕ") {
                this.certs.push(item)
              } else if (name == "SUP-ДОСКА") {
                this.sups.push(item)
              } else {
                this.prizes.push(item)
              }
            })

            if (this.winners.length < 1) {
              this.noResults = true
            }
          }).catch(()=>{
            this.sortedWinners = []
      })
    },
  }
}
</script>

<style scoped lang="scss">
.link {
  text-align: center;
  display: flex;
  justify-content: center;
  color: #0056b3;
  text-decoration: underline;
  position: absolute;
  bottom: 35px;
  transform: translateX(-150%);
  left: 50%;
  right: 50%;
  white-space: nowrap;

  @media(max-width: 576px) {
    font-size: 12px;
  }
}

.section-wrapper {
  background: #EEF3F5;
  padding-top: 160px;
  padding-bottom: 150px;
  color: $darker-grey;
  background-image: url('@/assets/winner-bg.png');
  background-position: center center;
  min-height: 100vh;
  position: relative;

  @media(max-width: 999px){
    padding-bottom: 70px;
    padding-top: 100px;
  }
}

h3 {
  @media(max-width: 768px){
    text-align: center;
  }
}

.actions-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media(max-width: 768px){
    flex-direction: column;
  }
}

.subtext {
  font-weight: 400 !important;
  font-size: 18px;
  line-height: 24px;
  margin-top: 10px;
  margin-bottom: 70px;

  @media(max-width: 768px){
    margin-bottom: 30px;
    text-align: left;
  }
}

.winners-content {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.table-wrapper {
  width: 100%;
  max-width: 900px;
  margin-left: 20px;

  @media(max-width: 1600px){
    max-width: 800px;
  }

  @media(max-width: 768px){
    margin-top: 20px;
    font-size: 12px;
    margin-left: 0;
  }

  .table-header {
    font-weight: 600;
    background-color: $blue;
    color: $white;
    text-align: center;
    font-family: $regular;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @media(max-width: 768px){
      font-size: 12px;
    }
  }

  .table-line {

    &:nth-child(2n + 1){
      background-color: $white;
    }
  }

  .table-line,
  .table-header {
    height: 57px;
    width: 100%;
    display: flex;
    align-items: center;
    border-radius: 57px;
    padding: 0 15px;

    div {
      text-align: center;
      width: 33%;
    }
  }
}

.video-frame {
  max-width: 790px;
  max-height: 800px;
  width: 100%;
  height: 100%;

  @media(max-width: 1600px){
    max-width: 490px;
    max-height: 500px;
  }

  @media(max-width: 768px){
    max-height: 250px;
    margin-bottom: 20px;
  }
}

video:-webkit-full-page-media {
  margin: 0 !important;
}

.section-title {
  text-align: center;
  color: $blue;
  font-weight: 700;
  font-size: 40px;
  line-height: 110%;
  margin-bottom: 40px;
  text-transform: uppercase;

  @media(max-width: 999px){
    text-align: center;
    font-size: 36px;
  }

  @media(max-width: 576px){
    font-size: 24px !important;
  }
}

.plug {
  margin-top: 100px;
  background-color: #141A28;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(100,100,100,0.7);
  border-radius: 20px;
  padding: 160px 190px;
  text-align: center;
  flex-shrink: 0;

  @media(max-width: 1600px){
    padding: 110px 90px;
  }

  @media(max-width: 999px){
    padding: 60px 30px;
    margin-right: 15px;
    font-size: 14px;
    white-space: nowrap;
  }
}

.table-title {
  font-weight: 700;
  font-size: 30px;
  font-family: $regular;
  line-height: 120%;
  margin-bottom: 30px;
  position: relative;
  z-index: 3;

  @media(max-width: 999px){
    font-size: 20px;
  }
}

.content-center {
  @media(max-width: 576px){
    justify-content: center;
  }
}

.search {
  border-radius: 95px;
  height: 50px;
  background-color: white;
  border: 2px solid $white;
  padding-left: 22px;
  color: $darker-grey !important;

  &::placeholder {
    color: $blue;
  }

  &:active,
  &:focus {
    box-shadow: none !important;
    outline: none !important;
    border: 2px solid $blue !important;
  }
}

.search-bar {
  display: flex;
  align-items: center;
  margin-right: 17px;
  position: relative;

  @media(max-width: 999px){
    flex-direction: column;

    .search {
      margin-bottom: 20px;
    }
  }
}

.button-search {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  z-index: 100;
  right: 0;
  margin-right: 10px;

  @media(max-width: 768px){
    margin-top: 7px;
  }
}

.video-wrapper {
  margin-top: 105px;

  @media(max-width: 576px){
    margin-top: 70px;
  }
}

.switch {
  text-decoration: underline;
  font-weight: 600;
  margin-top: 50px;
  cursor: pointer;
  font-family: $regular;

  &:hover {
    opacity: 0.85;
  }
}

.switch-tables {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  border-radius: 60px;
  background-color: white;
  color: #CACACA;
  font-weight: 600;
  font-size: 16px;
  line-height: 120%;
  height: 50px;

  .option {
    cursor: pointer;
    transition: 0.6s all;
    font-family: $regular;
    font-weight: 400;
    font-size: 16px;
    background-color: white;
    border-radius: 60px;
    height: 50px;
    margin: 0;
    padding: 0 29px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      opacity: 0.8;
    }

    &.active {
      background-color: $blue;
      color: $white !important;
    }
  }
}

.disabled {
  pointer-events: none;
}

.bold {
  font-weight: 700;
}
</style>
