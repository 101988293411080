<template>
  <div class="table-wrapper">
    <div class="dekstop-table">
      <b-row class="table-header">
        <div>Дата/время <br> загрузки чека</div>

        <div>Данные чека</div>

        <div>Статус</div>

        <div>Наименование <br> приза</div>
      </b-row>

      <div class="table">
        <div v-if="!receipts">
          <h3 style="color: #243E89;">НЕТ ДАННЫХ</h3>
        </div>

        <b-row class="table-line"
               v-if="receipts"
               v-for="receipt in receipts">
          <div>{{ receipt.created_at }}</div>

          <div>{{ receipt.price }}</div>

          <div class="d-flex align-items-start">
            {{ receipt.status.i18n }}
          </div>

          <div>
            <span>{{ receipt.prize }}</span>
          </div>
        </b-row>
      </div>
    </div>

    <!-- <div class="mobile-table">
      <b-row class="table-header">
        <div>Дата загрузки <br> Сумма чека / <br> Статус чека</div>

        <div>Наименование <br> приза</div>
      </b-row>

      <div class="table">
        <div v-if="!receipts">
          <h3>НЕТ ДАННЫХ</h3>
        </div>

        <b-row class="table-line"
               v-if="receipts"
               v-for="receipt in receipts">
          <div>{{ receipt.created_at }} <br> {{ receipt.price }} <br>
               <strong>
                 {{ receipt.status.i18n }}
               </strong>
          </div>

          <div>
            <span v-if="(receipt.prize === null)">―</span>

            {{ receipt.prize }}
          </div>
        </b-row>
      </div>
    </div> -->
  </div>
</template>

<script>
import axios from 'axios'
import { mapState, mapGetters, mapMutations, mapActions } from "vuex"

export default {
  name: 'OfficeTable',

  data: () => ({
  }),

  computed: {
    ...mapGetters({
      receipts: 'receipts',
    }),
  },

  mounted(){
    this.$store.dispatch('getReceipts')
  },

  methods: {
  },
}
</script>

<style scoped lang="scss">
.table-wrapper {
  width: 100%;
}

.table-header {
  width: 100%;
  margin-bottom: 15px;
  padding: 10px 45px;
  text-align: left;
  border-radius: 40px;
  background: $blue;
  box-shadow: 0px 20px 30px -20px rgba(46, 36, 123, 0.35), 0px 10px 20px -20px #2E247B;
  color: $white;

  @media(max-width: 768px){
    margin: 0;
    margin-bottom: 15px;
    padding: 10px 5px;
  }

  div {
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    text-align: center;
    font-family: $regular;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;

    @media(max-width: 768px){
      font-size: 10px;
    }
  }
}

.row {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.table-line {
  width: 100%;
  text-align: left;
  padding: 20px 45px;
  padding-right: 20px;
  color: $darker-grey;
  background-color: transparent;
  border-radius: 40px;

  &:nth-child(2n){
    background: white;
  }

  &:last-child {
    border-bottom: none;
  }

  @media(max-width: 768px){
    margin: 0;
    padding: 15px 5px;
  }

  div {
    width: 25%;
    display: flex;
    align-items: center !important;
    justify-content: center;
    color: $darker-grey;
    text-align: center;
    font-family: $regular;
    font-size: 14px;
    font-style: normal;
    line-height: normal;

    @media(max-width: 768px){
      font-size: 10px;
    }
  }
}

.table {
  max-height: 500px;
  overflow-y: scroll;
  padding: 0;
  border-radius: 25px;

  @media(max-width: 768px){
    padding: 0px;
    max-height: 300px;
  }

  ::webkit-scrollbar {
    width: 0 !important;
    -ms-overflow-style: none !important;
    scrollbar-width: none !important;
  }
}

.table {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.table::-webkit-scrollbar { 
  width: 0 !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.table::-webkit-scrollbar {
  display: none;
}

h3 {
  font-weight: 700;
  font-size: 22px;
  margin: 15px;
  color: $blue;
}

.dekstop-table {
  width: 100%;
}

.mobile-table {
  display: none;
  width: 100%;

  @media(max-width: 768px){
    display: block;
  }
}
</style>
