<template>
  <section class="section-wrapper"
           id="promo">
    <div class="wrapper">
      <div class="promo-block">
          <div class="promo-title">
            <h5>с 1 ноября по 15 декабря 2024 года</h5>

            <h1>Активная зима <br> с линзами Alcon</h1>
          </div>

          <router-link class="button button-orange"
                       onclick="ym(94436170,'reachGoal','2024_winter')"
                       :to="{ path: '/#mechanics' }"
                       v-scroll-to="'#mechanics'">
            Участвовать
          </router-link>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  name: 'Promo',

  data: () => ({
  }),

  computed: {
  },
}
</script>

<style scoped lang="scss">
.section-wrapper {
  background-color: white;
  background-repeat: no-repeat;
  padding: 0;
  background-position: center right;
  color: $white;
  height: calc(100vh - 68px);
  background-size: cover;
  display: flex;
  font-family: $regular;
  height: 125vh;
  justify-content: center;
  background-image: url('@/assets/visual-bg.webp');

  @media(max-width: 1200px){
    padding-left: 60px;
    padding-right: 60px;
  }

  @media(max-width: 1080px){
    padding-left: 35px;
    padding-right: 35px;
    align-items: flex-start;
    background-image: url('@/assets/visual-bg@tablet.webp');
    padding-top: 110px;
    background-size: contain;
    background-position: top center;
    height: 110vh;
  }

  @media(max-width: 820px){
    align-items: flex-start;
    background-size: 100%;
    height: 45vh;
  }

  @media(max-width: 576px){
    padding-left: 20px;
    padding-top: 90px;
    background-image: url('@/assets/visual-bg@mobile.png');
    height: fit-content;
    background-size: 105%;
    background-position: top -80px left;
  }

  @media(max-width: 470px){
    padding-top: 80px;
  }

  @media(max-width: 420px){
    // background-size: contain;
  }

  @media(max-width: 395px){
    // background-size: contain;
  }

  @media(max-width: 380px){
    padding-top: 70px;
  }

  @media(max-width: 340px) {
    padding-top: 50px;
  }
}

.wrapper {
  max-width: 1700px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
  padding: 55px 75px;

  @media(max-width: 1600px){
    max-width: 1300px;
    padding: 5px 75px;
  }

  @media(max-width: 1400px){
    max-width: 1130px;
  }

  @media(max-width: 1100px) {
    padding: 5px 0px;
  }

  @media(max-width: 576px) {
    justify-content: center;
    height: 100%;
    align-items: space-between;
  }
}

.promo-block {
  display: flex;
  height: fit-content;
  flex-direction: column;
  padding-top: 225px;

  @media(max-width: 1400px){
    padding-top: 225px;
  }

  @media(max-width: 1080px) {
    padding-top: 9%;
  }

  @media(max-width: 756px){
    padding-top: 0px;
  }

  @media(max-width: 576px) {
    text-align: center;
    height: 100%;
    align-items: center;
  }

  .img {
    max-width: 490px;
    margin-right: 35px;
  }

  .promo-title {
    font-weight: 700;
    font-size: 82px;
    text-transform: uppercase;
    font-family: $regular;
    z-index: 2;
    color: $blue;
    position: relative;

    @media(max-width: 576px) {
      margin-top: 20px;
    }
  }

  .button {
    min-width: 250px;
    height: 59px;
    border-radius: 52px;
    margin-top: 59px;

    &:hover {
      text-decoration: none;
    }

    @media(max-width: 1050px){
      min-width: 180px;
      max-width: 190px;
      height: 53px;
      font-weight: 700;
      font-size: 14px;
      line-height: 120%;
      margin-top: 39px;
    }

    @media(max-width: 576px) {
      margin-top: 230px;

      @media(min-height: 830px){
        margin-top: 290px;
      }
    }

    @media(max-width: 420px){
      min-width: 180px;
      max-width: 190px;
    }

    @media(max-width: 350px){
    }
  }
}


h5 {
  font-family: $regular;
  font-size: 20px;
  font-weight: 600;
  line-height: 110%;
  margin-bottom: 24px;

  @media(max-width: 1500px) {
    font-size: 23px;
  }

  @media(max-width: 1200px) {
    font-size: 16px;
  }

  @media(max-width: 999px) {
    margin-bottom: 9px;
  }

  @media(max-width: 576px) {
    font-size: 14px;
  }

  @media(max-width: 340px) {
    font-size: 12px;
  }
}

h1 {
  font-family: $regular;
  font-size: 52px;
  font-weight: 900;
  margin-bottom: 0;

  @media(max-width: 1500px) {
    font-size: 58px;
  }

  @media(max-width: 1200px) {
    font-size: 36px;
  }

  @media(max-width: 576px) {
    font-size: 30px;
  }

  @media(max-width: 380px) {
    font-size: 26px;
  }

  @media(max-width: 340px) {
    font-size: 24px;
  }
}
</style>
