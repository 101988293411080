<template>
  <div class="home">
    <Promo />

    <Prizes />

    <Mechanics />

    <BuyLenses />
  </div>
</template>

<script>
import Promo from '@/components/Promo'
import Prizes from '@/components/Prizes'
import Mechanics from '@/components/Mechanics'
import BuyLenses from '@/components/BuyLenses'

export default {
  name: 'HomeView',

  components: {
    Promo,
    Mechanics,
    BuyLenses,
    Prizes,
  }
}
</script>