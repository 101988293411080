<template>
  <b-modal
    id="cash-voucher-modal"
    ref="modal"
    title=""
    hide-footer
    hide-header
    centered
  >
    <div class="header">
      <span class="close-icon"
            @click="closeModal">
        <img src="@/assets/svg/close.svg"
             aria-label="close">
      </span>

      <h3 style="text-align: center; width: 100%;">
        Введите данные чека
      </h3>
    </div>

    <div class="content">
      <!-- <img src="@/assets/voucher-sample.png"
           class="sample"
           @click="openSample" /> -->

      <div  ref="form"
            class="form modal-form"
            @submit.stop.prevent="submit">
        <div class="form-inputs">
          <div class="d-flex justify-content-between">
            <b-form-group
              id="total-input"
              label-for="total-input"
              invalid-feedback="Некорректное поле"
            >
              <b-form-input
                id="total-input"
                placeholder="Сумма"
                required
                type="text"
                v-model="$v.item.s.$model"
                :state="validateState($v.item.s)"
              ></b-form-input>

              <div class="input-icon" v-if="$v.item.s.$error">
                <img src="@/assets/svg/incorrect.svg" />
              </div>

              <div class="input-icon" v-if="!$v.item.s.$error && item.s.length > 1">
                <img src="@/assets/svg/correct.svg" />
              </div>
            </b-form-group>

            <b-form-group
              id="fn-input"
              label-for="fn-input"
              invalid-feedback="Некорректное поле"
            >
              <b-form-input
                id="fn-input"
                placeholder="ФН"
                required
                type="text"
                v-model="$v.item.fn.$model"
                :state="validateState($v.item.fn)"
              ></b-form-input>

              <div class="input-icon" v-if="$v.item.fn.$error">
                <img src="@/assets/svg/incorrect.svg" />
              </div>

              <div class="input-icon" v-if="!$v.item.fn.$error && item.fn.length > 1">
                <img src="@/assets/svg/correct.svg" />
              </div>
            </b-form-group>
          </div>

          <div class="d-flex justify-content-between">
            <b-form-group
              id="date-input"
              label-for="date-input"
              invalid-feedback="Некорректная дата"
              v-mask="'##.##.####'"
            >
              <b-form-input
                id="date-input"
                placeholder="Дата"
                required
                type="text"
                v-model="$v.item.date.$model"
                :state="validateState($v.item.date)"
              ></b-form-input>

              <div class="input-icon" v-if="$v.item.date.$error">
                <img src="@/assets/svg/incorrect.svg" />
              </div>

              <div class="input-icon" v-if="!$v.item.date.$error && item.date.length > 1">
                <img src="@/assets/svg/correct.svg" />
              </div>
            </b-form-group>

            <b-form-group
              id="fd-input"
              label-for="fd-input"
              invalid-feedback="Некорректное поле"
            >
              <b-form-input
                id="fd-input"
                placeholder="ФД"
                required
                type="text"
                v-model="$v.item.fd.$model"
                :state="validateState($v.item.fd)"
              ></b-form-input>

              <div class="input-icon" v-if="$v.item.fd.$error">
                <img src="@/assets/svg/incorrect.svg" />
              </div>

              <div class="input-icon" v-if="!$v.item.fd.$error && item.fd.length > 1">
                <img src="@/assets/svg/correct.svg" />
              </div>
            </b-form-group>
          </div>

          <div class="d-flex justify-content-between">
            <b-form-group
              id="time-input"
              label-for="time-input"
              invalid-feedback="Некорректное время"
              v-mask="'##:##'"
            >
              <b-form-input
                id="time-input"
                placeholder="Время"
                required
                type="text"
                v-model="$v.item.time.$model"
                :state="validateState($v.item.time)"
              ></b-form-input>

              <div class="input-icon" v-if="$v.item.time.$error">
                <img src="@/assets/svg/incorrect.svg" />
              </div>

              <div class="input-icon" v-if="!$v.item.time.$error && item.time.length > 1">
                <img src="@/assets/svg/correct.svg" />
              </div>
            </b-form-group>

            <b-form-group
              id="fp-input"
              label-for="fp-input"
              invalid-feedback="Некорректное поле"
            >
              <b-form-input
                id="fp-input"
                placeholder="ФДП или ФП"
                required
                type="text"
                v-model="$v.item.fp.$model"
                :state="validateState($v.item.fp)"
              ></b-form-input>

              <div class="input-icon" v-if="$v.item.fp.$error">
                <img src="@/assets/svg/incorrect.svg" />
              </div>

              <div class="input-icon" v-if="!$v.item.fp.$error && item.fp.length > 1">
                <img src="@/assets/svg/correct.svg" />
              </div>
            </b-form-group>
          </div>

          <div class="button-wrapper">
            <b-button class="button button-orange"
                      type="submit"
                      onclick="ym(94436170,'reachGoal','send_check')"
                      :disabled="disabledBtn"
                      @click="submitReceipt">Загрузить данные</b-button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import axios from 'axios'
import QrScanner from 'qr-scanner'
import { validationMixin } from "vuelidate"
import { mapGetters, mapMutations } from "vuex"
import { required, requiredIf, minLength, maxLength, or, helpers } from "vuelidate/lib/validators"

const number = helpers.regex('number', /^[:]*[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)

export default {
  name: "ManualVoucher",

  components: {
  },

  mixins: [validationMixin],

  validations: {
    item: {
      fp: {
        required,
        number,
      },
      fn: {
        required,
        number,
      },
      fd: {
        required,
        number,
      },
      date: {
        required,
        minLength: minLength(10),
        maxLenght: maxLength(10),
        number,
      },
      time: {
        required,
        minLength: minLength(5),
        maxLenght: maxLength(5),
      },
      s: {
        required,
        number,
      },
    }
  },

  data(){
    return{
      item: {
        fp: '',
        fn: '',
        fd: '',
        date: '',
        time: '',
        s: ''
      },
      dropOptions: {
        url: "https://httpbin.org/post",
        acceptedFiles:'image/png, image/jpg, image/jpeg, image/bmp',
        maxFilesize: 20, // MB
      },
      sent: false,
      isLoading: false,
      result: '',
      files: [],
      qrData: [],
      t: '',
    }
  },

  computed: {
    ...mapGetters({
      token: 'token',
      user: 'user'
    }),

    disabledBtn(){
      if ((this.$v.item.$anyError) || (this.item.fp.length < 1) || (this.item.fn.length < 1)
          || (this.item.fd.length < 1) || (this.item.date.length < 1) || (this.item.time.length < 1)
          || (this.item.s.length < 1) || (this.sent))
        return true
    },
  },

  methods: {
    ...mapMutations({
      saveToken: 'saveToken',
      saveUser: 'saveUser',
      getSuccessText: 'getSuccessText',
      getErrorText: 'getErrorText',
      saveVoucherDataFp: 'saveVoucherDataFp',
      saveVoucherDataFd: 'saveVoucherDataFd',
      saveVoucherDataFn: 'saveVoucherDataFn',
      saveVoucherDataT: 'saveVoucherDataT',
      saveVoucherDataS: 'saveVoucherDataS',
      saveVoucherDataDate: 'saveVoucherDataDate',
      saveVoucherDataTime: 'saveVoucherDataTime',
    }),

    openSample(){
      this.$bvModal.show('sample-modal')
    },

    closeModal(){
      this.$bvModal.hide('cash-voucher-modal')

      this.files = [],
      this.item.fp = '',
      this.item.fd = '',
      this.item.fn = '',
      this.item.date = '',
      this.item.time = '',
      this.item.s = ''

      this.saveQrData()
    },

    resetModal(){
      this.$bvModal.hide('cash-voucher-modal')

      this.files = [],
      this.item.fp = '',
      this.item.fd = '',
      this.item.fn = '',
      this.item.date = '',
      this.item.time = '',
      this.item.s = ''

      this.saveQrData()
    },

    saveQrData(){
      this.saveVoucherDataFp(this.item.fp)
      this.saveVoucherDataFn(this.item.fn)
      this.saveVoucherDataFd(this.item.fd)
      this.saveVoucherDataS(this.item.s)
      this.saveVoucherDataT(this.item.t)
      this.saveVoucherDataDate(this.item.date)
      this.saveVoucherDataTime(this.item.time)
    },

    validateState(item) {
      const { $dirty, $error } = item
      return $dirty ? !$error : null
    },

    submitReceipt() {
      this.sent = true

      const formData = new FormData()
      formData.append('fp', this.item.fp)
      formData.append('fn', this.item.fn)
      formData.append('fd', this.item.fd)
      formData.append('datetime', this.item.date + ' ' + this.item.time)
      formData.append('price', this.item.s)

      axios.post('profile/receipts', formData, {
      }, {
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'access-control-allow-origin': "*",
          'Authorization': "Bearer " + localStorage.getItem('token')
        }
      }).then((response) => {
        this.$bvModal.hide('cash-voucher-modal')
        this.$bvModal.show('success-upload-modal')

        this.sent = false
      }).catch((error) => {
        this.sent = false

        if (error.response.data.message === "Время жизни сессии истекло. Пожалуйста, авторизуйтесь"){
          this.getErrorText(error.response.data.message)

          this.logout()
          this.$bvModal.hide('cash-voucher-modal')
          this.$bvModal.show('error-modal')
        }

        if (error.response.data.message){
          this.getErrorText(error.response.data.message)
          this.$bvModal.hide('cash-voucher-modal')

          this.$bvModal.show('error-modal')
        }
      })
    },

    getCode(){
      this.$v.item.$touch()
      this.saveQrData()

      if (this.$v.item.$anyError)
        return false

      axios.post('voix/send', {
        phone: this.user.phone,
      }, {
        headers: {
          'Accept': "application/json",
          'Content-Type': "application/json",
          'access-control-allow-origin': "*",
        }
      }).then((response) => {
        this.resetModal()
        this.$bvModal.show('code-check-modal')
        this.$bvModal.hide('cash-voucher-modal')
      }).catch((error) => {
        this.$bvModal.show('error-modal')
        this.resetModal()
        this.getErrorText(error.response.data.message)
      })
    },
  }
}
</script>
<style scoped lang="scss">
.form-group {
  position: relative;
  font-family: $regular !important;

  .input-icon {
    position: absolute;
    color: $red;
    font-size: 18px;
    font-family: $regular;
    top: 0;
    margin-left: 15px;
    margin-top: 12px;
  }
}

h3 {
  margin-left: 10px;
}

.form-control {
  color: $darker-grey !important;
  background-color: transparent !important;
  border: 2px solid white;
  transition: .6s all;
  height: 60px;
  display: flex;
  padding-left: 25px !important;
  align-items: center;
  font-size: 18px;
  margin-top: 0px;
  border-radius: 62px;
  min-width: none;
  max-width: 281px;
  width: 281px;
  font-family: $regular;
  margin-left: 10px;
  margin-right: 10px;

  &::placeholder {
    color: #C4C4C4 !important;
  }

  &:focus {
    box-shadow: none;
    border: 1px solid $darker-blue;
  }

  @media(max-width: 999px){
    min-width: none;
  }

  @media(max-width: 768px){
    min-width: 300px !important;
    max-width: 300px !important;
    margin-left: 0;
    height: 48px;
    margin-right: 0;
  }
}

.form-group {
  margin-bottom: 16px;
}

.button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 30px;

  .button {
    width: 100%;
    max-width: 345px;
    margin-right: 10px;

    @media(max-width: 768px){
      margin-right: 0;
      max-width: 350px;
      height: 48px;
      margin-right: 7px;
      margin-bottom: 20px;
    }
  }
}

.content {
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media(max-width: 768px){
    flex-direction: column;
    align-items: center;
  }

  .d-flex {
    @media(max-width: 768px){
      flex-direction: column;
      align-items: center;
    }
  }
}

.sample {
  max-width: 100px;
  max-height: 160px;
  margin-top: 20px;
  cursor: pointer;

  @media(max-width: 768px){
    margin-top: 0;
    margin-bottom: 24px;
    max-width: 155px;
    max-height: 255px;
  }
}

.button {
  max-width: 326px;
  margin-top: 95px;
}

.form-inputs {
  @media(max-width: 576px){
    padding: 0 10px !important;
  }
}

.form {
  width: 100%;
}

.invalid-feedback {
  color: black !important;
  margin-left: 10px !important;
}
</style>
