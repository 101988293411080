<template>
  <header class="">
    <div class="header-wrapper">
      <div class="desktop d-flex align-items-center">
        <div class="d-flex align-items-center block block__blue">
          <a class="header-link promo-logo"
             target="_blank"
             onclick="ym(94436170,'reachGoal','moiglaza')"
             href="https://www.moiglaza.ru/">
            <img src="@/assets/svg/promo-logo.svg" />
          </a>

          <a href="http://www.moiglaza.ru/club"
             target="_blank"
             class="header-link club-logo">
            <img src="@/assets/logo.webp" />
          </a>
        </div>

        <div class="d-flex align-items-center w-100">
          <div class="d-flex align-items-center justify-content-around block block__white w-100">
            <router-link :to="{ path: '/#mechanics' }"
                         v-scroll-to="'#mechanics'"
                         class="header-link">
              Условия участия
            </router-link>

            <a target="_blank" href="https://www.moiglaza.ru/adresa-optik/?promo=winter2024&utm_source=website&utm_medium=website_url&utm_campaign=promo_winter2024"
               onclick="ym(94436170,'reachGoal','Header_buy')"
               class="header-link">
              Где купить
            </a>

            <router-link :to="{ path: '/#prizes' }"
                         v-scroll-to="'#prizes'"
                         class="header-link">
              Призы
            </router-link>

            <router-link :to="{ path: '/winners' }"
                         v-scroll-to="'#winners'"
                         class="header-link">
              Победители
            </router-link>
          </div>

          <div class="d-flex align-items-center block block__blue"
               v-if="user"
               onclick="ym(94436170,'reachGoal','lk_click')"
               @click="openOffice">
            <div class="profile">
              <img src="@/assets/svg/profile.svg" />
            </div>

            <p class="header-link no-tablet" style="color: white; margin: 0; margin-left: 8px; white-space: nowrap;">
              Личный кабинет
            </p>
          </div>

          <router-link class="d-flex align-items-center block block__blue no-tablet"
                       v-if="!user"
                       onclick="ym(94436170,'reachGoal','lk_click')"
                       :to="{ path: '/#mechanics' }"
                       v-scroll-to="'#mechanics'">
            <div class="profile">
              <img src="@/assets/svg/profile.svg" />
            </div>

            <p class="header-link" style="color: white; margin: 0; margin-left: 8px; white-space: nowrap;">
              Личный кабинет
            </p>
          </router-link>

          <router-link class="d-flex align-items-center block block__white tablet"
                       v-if="!user"
                       onclick="ym(94436170,'reachGoal','lk_click')"
                       :to="{ path: '/#mechanics' }"
                       v-scroll-to="'#mechanics'">
            <div class="profile">
              <img src="@/assets/svg/profile@mobile.svg" />
            </div>
          </router-link>
        </div>
      </div>

      <div class="mobile d-flex  align-items-center">
        <div class="d-flex align-items-center block block__blue">
          <a class="header-link promo-logo"
             target="_blank"
             href="https://www.moiglaza.ru/">
            <img loading="lazy" src="@/assets/svg/promo-logo.svg" />
          </a>

          <a href="http://www.moiglaza.ru/club"
             target="_blank"
             class="header-link club-logo">
            <img loading="lazy" src="@/assets/logo.webp" />
          </a>
        </div>

        <div class="d-flex align-items-center block block__white">
          <div class="profile"
               v-if="user"
               onclick="ym(94436170,'reachGoal','lk_click')"
               @click="openOffice">
            <img loading="lazy" src="@/assets/svg/profile@mobile.svg" />
          </div>

          <router-link :to="{ path: '/#mechanics' }"
                       v-scroll-to="'#mechanics'"
                       class="profile"
                       onclick="ym(94436170,'reachGoal','lk_click')"
                       v-if="!user">
            <img src="@/assets/svg/profile@mobile.svg" />
          </router-link>

          <div class="mobile-icon"
               @click="mobileMenuOpened = !mobileMenuOpened">
            <img src="@/assets/svg/burger-opened.svg"
                 v-if="mobileMenuOpened" />

            <img src="@/assets/svg/burger-icon.svg"
                 style="max-width: 20px;"
                 v-if="!mobileMenuOpened" />
          </div>
        </div>
      </div>
    </div>

    <div class="mobile-menu"
         :class="{ active: mobileMenuOpened }">
       <router-link :to="{ path: '/#mechanics' }"
                    v-scroll-to="'#mechanics'"
                    class="header-link"
                    @click.native="mobileMenuOpened = !mobileMenuOpened">
         Условия участия
       </router-link>

       <a target="_blank" href="https://www.moiglaza.ru/adresa-optik/?promo=winter2024&utm_source=website&utm_medium=website_url&utm_campaign=promo_winter2024"
               onclick="ym(94436170,'reachGoal','Header_buy')"
                    class="header-link">
              Где купить
       </a>

       <router-link :to="{ path: '/#prizes' }"
                    v-scroll-to="'#prizes'"
                    class="header-link"
                    @click.native="mobileMenuOpened = !mobileMenuOpened">
         Призы
       </router-link>

       <router-link :to="{ path: '/winners' }"
                    v-scroll-to="'#winners'"
                    class="header-link"
                    @click.native="mobileMenuOpened = !mobileMenuOpened">
         Победители
       </router-link>

       <!-- <button class="button button-orange mt-4">
         Личный кабинет
       </button> -->
    </div>
  </header>
</template>

<script>
import axios from 'axios'
import { mapGetters, mapMutations } from "vuex"

export default {
  name: 'HeaderRegion',

  data: () => ({
    mobileMenuOpened: false,
  }),

  computed: {
    ...mapGetters({
      user: 'user',
      token: 'token'
    }),
  },

  methods: {
    ...mapMutations({
      saveUser: 'saveUser',
      saveToken: 'saveToken',
      getSuccessText: 'getSuccessText',
      getErrorText: 'getErrorText',
    }),

    openOffice(){
      this.$router.push('/office')
    },

    logout(){
      this.saveToken(null)
      this.saveUser(null)
      localStorage.removeItem('token')
    }
  }
}
</script>

<style scoped lang="scss">
header {
  position: fixed;
  width: 100%;
  z-index: 9999;
  padding-top: 60px;
  display: flex;
  justify-content: center;
  padding-right: 16px;

  @media(max-width: 1500px){
    padding-right: 5px;
  }

  @media(max-width: 1080px){
    padding-top: 0px;
    padding-top: 23px;
  }

  @media(max-width: 576px){
    padding-top: 20px;
  }
}

.header-wrapper {
  height: 58px;
  z-index: 9999;
  font-family: $regular;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
  max-width: 1600px;
  width: 100%;

  @media(max-width: 1600px){
    max-width: 1150px;
  }

  @media(max-width: 1400px){
    max-width: 1000px;
  }

  @media(max-width: 1200px){
    padding: 0 25px;
    max-width: 900px;
  }

  @media(max-width: 1080px){
    padding: 0 10px;
    padding-top: 10px;
    width: 100%;
  }

  @media(max-width: 756px){
    height: 45px;
    padding: 0 10px;
    width: 100%;
  }

  @media(max-width: 500px){
    height: 55px;
  }

  @media(max-width: 340px){
    height: 45px;
  }
}

.block {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 58px;
  padding: 0 35px;
  gap: 4px;
  border-radius: 12px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

  @media(max-width: 756px){
    padding: 0;
    height: 45px;
    justify-content: center;
    width: 100%;
  }

  @media(max-width: 500px){
    height: 55px;
  }

  @media(max-width: 340px){
    height: 45px;
  }

  &__blue {
    background-color: $blue;

    @media(max-width: 1080px){
      padding: 0 15px;
      width: fit-content;
    }

    @media(max-width: 576px){
      width: 100%;
    }

    @media(max-width: 360px){
      padding: 0 10px;
    }

    @media(max-width: 330px){
      padding: 0 7px;
    }
  }

  &__white {
    background-color: $white;
    margin-left: 4px;
    margin-right: 4px;

    @media(max-width: 1080px){
      padding: 0 15px;
      width: fit-content;
    }

    @media(max-width: 576px){
      max-width: 105px;
    }

    @media(max-width: 340px){
      max-width: 80px;
    }
  }
}

.promo-logo {
  border-right: 1px solid $white;
  padding-right: 20px;
  margin-right: 15px !important;

  @media(max-width: 1080px){
    border-right: 2px solid $white;
    padding-right: 15px;
    margin-right: 10px !important;
  }

  @media(max-width: 756px){
    padding-right: 20px;
    margin-right: 10px !important;
  }
}

.profile {
  cursor: pointer;
  transition: .5s all;

  &:hover {
    opacity: .9;
  }

  @media(max-width: 1080px){

    img {
      max-width: 24px;
    }
  }

  @media(max-width: 340px){

    img {
      max-width: 20px;
    }
  }
}

.no-tablet {
  @media(max-width: 1080px){
    display: none !important;
  }
}

.club-logo {
  img {
    max-width: 145px !important;
    max-height: 30px;

    @media(max-width: 756px){
      max-width: 120px !important;
    }

    @media(max-width: 400px){
      max-width: 100px !important;
    }
  }
}

.header-link {
  cursor: pointer;
  color: $blue;
  font-family: $regular;
  font-weight: 600 !important;
  text-decoration: none;
  font-size: 16px;
  line-height: 120%;
  transition: .4s all ease;
  margin-right: 30px;
  white-space: nowrap;

  &:hover {
    opacity: .85 !important;
  }

  &:last-child {
    margin-right: 0;
  }

  @media(max-width: 1400px){
    margin-right: 15px;
    font-size: 14px;
  }

  @media(max-width: 576px){
    color: $white;
    white-space: nowrap;
  }

  img {
    max-width: 95px;

    @media(max-width: 756px){
      max-width: 80px;
    }

    @media(max-width: 500px){
      max-width: 110px;
    }

    @media(max-width: 400px){
      max-width: 90px;
    }

    @media(max-width: 340px){
      max-width: 70px;
    }
  }
}

.router-link-exact-active {
  font-weight: 700;
}

.mobile-icon {
  transition: 1s all;
  margin-left: 15px;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: none;
  margin-left: 15px;
  align-items: center;
  justify-content: center;

  @media(max-width: 1080px){
    display: flex;
    margin-left: 10px;
  }

  @media(max-width: 340px){

    img {
      max-width: 20px;
    }
  }

  &:hover {
    opacity: 0.9;
  }

  img {
    margin: 0;
    transition: 1s all;
  }
}

.mobile-menu {
  background-color: $darker-blue;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 25px;
  position: fixed;
  width: 100vw;
  margin-top: -400px;
  transition: .8s all ease;
  z-index: 999;

  .header-link {
    font-size: 16px;
    display: block !important;
    margin-top: 20px;
    font-weight: 500 !important;
    margin-left: 0;
    margin-right: 0 !important;
    color: white !important;

    &:first-child {
      margin-top: 0;
    }
  }

  &.active {
    margin-top: 0px;
    border-bottom: 1px solid #869BC7;
    box-shadow: 0px 4px 13px 0px rgba(18, 31, 76, 0.54);


    @media(max-width: 1080px){
      margin-top: 70px;
    }
  }
}

.desktop {
  width: 100%;

  @media(max-width: 756px){
    display: none !important;
  }
}

.mobile {
  width: 100%;

  display: none !important;

  @media(max-width: 756px){
    display: flex !important;
    justify-content: space-between;
  }
}

.btn-link {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white !important;

  &:hover {
    text-decoration: none;
    color: $white !important;
  }
}

.tablet {
  display: none !important;

  @media(max-width: 1080px) {
    display: flex !important;
  }

  @media(max-width: 576px) {
    display: none !important;
  }
}
</style>
